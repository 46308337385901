define('ajax', ['$window'], function($window) {

  /**
   * Public AJAX methods
   */
  var ajax = {

    /**
     * Return list of config settings for the ajax call
     * @returns {object}
     */
    help: function() {
      return ajax.configCheck({});
    },

    /**
     * Get data from URL
     * @param {object} config
     */
    get: function(config) {
      if (!config) {
        config = {};
      }

      config.type = 'GET';
      ajax.call(config);
    },

    /**
     * Post data to URL
     * @param {object} config
     */
    post: function(config) {
      if (!config) {
        config = {};
      }
      config.type = 'POST';
      ajax.call(config);
    },

    /**
     * Put data to URL
     * @param {object} config
     * @returns {object}
     */
    put: function(config) {
      if (!config) {
        config = {};
      }
      config.type = 'PUT';
      ajax.call(config);
    },

    /**
     * Call AJAX functionality
     * @param {object} config
     * @returns {string | JSON | undefined}
     */
    call: function(config) {
      if (!config) {
        config = {};
      }

      config = ajax.configCheck(config);

      if (!config.url) {
        console.error('Invalid value of param', 'Expected url to be String, got ' + typeof config.url, 'ajax.call');
        return;
      }

      var call = ajax.getRequestObject(config.crossDomain);

      var onLoad = ajax.getLoad(call);

      call.open(config.type, config.url, true);

      call[onLoad] = function() {
        ajax.onLoad(call, config);
      };

      if (call.onerror) {
        call.onerror = function(e) {
          ajax.onError(e, config);
        };
      }

      if (call.withCredentials && config.crossDomain) {
        call.withCredentials = true;
      }

      if (config.requestHeader && call.setRequestHeader) {
        call.setRequestHeader(config.requestHeader.header, config.requestHeader.value);
      }

      call.send(ajax.send(config));
    },

    /**
     * Check params for AJAX methods
     * @param {object} config
     * @returns {object | undefined}
     */
    configCheck: function(config) {
      if (!config) {
        config = {};
      }

      return {
        // A string containing the URL to which the request is sent
        url: config.url || undefined,
        // What type of call do you want to make GET, POST, PUT
        type: config.type || 'GET',
        // Send data to URL (string), if POST or PUT
        send: config.send || null,
        // What is the data type expected e.g. JSON
        dataType: config.dataType || '',
        // Is the URL on a different domain, prevents CORS (Cross-origin resource sharing) errors NOTE: callback function must contain JSONP function
        crossDomain: config.crossDomain || false,
        // Request headers on call e.g. 999 or experiment
        requestHeader: config.requestHeader || false,
        // Error callback function
        error: config.error || '',
        // Success callback
        success: config.success || ''
      };
    },

    /**
     * Get request object
     * @param {boolean} crossDomain
     * @returns {object}
     */
    getRequestObject: function(crossDomain) {
      if (crossDomain && !/MSIE 1/.test($window.navigator.userAgent)) {
        return new XDomainRequest();
      } else {
        return new XMLHttpRequest();
      }
    },

    /**
     * Get load type
     * @param call
     * @returns {string}
     */
    getLoad: function(call) {
      return call.onload === null ? 'onload' : 'onreadystatechange';
    },

    /**
     * Ajax success handler
     * @param call
     * @param config
     * @returns {object | string}
     */
    onLoad: function(call, config) {
      if (call.readyState === 4) {
        var status = call.status;

        if (status === 200) {
          config.responseText = call.response || call.responseText;
          return ajax.parseResponse(config);
        }

        if (status === 400) {
          return ajax.errorMethod(config.error, [call.responseText]);
        }

        if (status === 404) {
          return ajax.errorMethod(config.error, ['AJAX error: ', 'status 404', 'AJAX call was not found for ' + config.url]);
        }
        return ajax.errorMethod(config.error, ['AJAX error: ', 'status ' + status, 'AJAX call failed for ' + config.url]);
      }
    },

    errorMethod: function(error, params) {
      if (error) {
        return error.apply(this, params);
      }
      return console.warn.apply(this, params);
    },

    /**
     * Ajax error handler
     * @param e
     * @param config
     * @returns {object}
     */
    onError: function(e, config) {
      if (typeof config.error === 'function') {
        return config.error(e);
      }
      console.error('AJAX warning: ', e);
    },

    /**
     * What should be sent with the request to the URL
     * @param config
     * @returns {string}
     */
    send: function(config) {
      if (config.type.toLowerCase() !== 'get') {
        if (typeof config.send === 'string') {
          return config.send;
        } else {
          return JSON.stringify(config.send);
        }
      }
      return null;
    },

    /**
     * Parse data from request
     * @param config
     * @returns {JSON | string}
     */
    parseResponse: function(config) {
      if (config.dataType && config.dataType.toLowerCase() === 'json') {
        if (ajax.isJSON(config.responseText)) {
          config.responseText = JSON.parse(config.responseText);
        }
      }
      if (typeof config.success === 'function') {
        return config.success(config.responseText);
      }
      return config.responseText;
    },

    /**
     * Check if string is JSON
     * @param string
     * @returns {boolean}
     */
    isJSON: function(string) {
      if (typeof string === 'string') {
        try {
          JSON.parse(string);
        } catch (e) {
          return false;
        }
        return true;
      }
      return false;
    }
  };

  return ajax;
});
